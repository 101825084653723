import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getModelVRList = async (query)=> {
  try {
      const response = await instance.get(`/modelvr`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const getQuestionList = async (query)=> {
  try {
      const response = await instance.get(`/question`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const getClassroomByID = async (id) => {
    try {
        const response = await instance.get(`/classroom/get/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const getClassChapterByID = async (id) => {
  try {
      const response = await instance.get(`/classchapter/${id}`);
      if(response.status === 200){
          return response.data.data;
      }else{
          return null
      }
    } catch (error) {
      return null
    }
};

export const getTeacherList = async (query)=> {
  try {
      const response = await instance.get(`/teacher`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const getCertificateList = async (query)=> {
  try {
      const response = await instance.get(`${API_BASE_URL}/themecertificate`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const getList = async (query)=> {
    try {
        const response = await instance.get(`/classlesson`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`/classlesson/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (data) => {
    try {
      const toCreate = {
        cll_order: data?.no,
        cll_name: data?.name,
        cll_type: data?.type,
        cll_timer: data?.timer,
        cll_round: data?.round,
        cll_point: data?.point,
        cll_group: data?.group,
        cll_content: data?.content,
        classroom_id: data?.classroom_id,
        section_id: data?.section_id,
        chapter_id: data?.chapter_id,
        modelvr: data?.modelvr,
        questions: data?.questions ?? [],
        is_active: data?.is_active
      }

        const response = await instance.post(`/classlesson`,toCreate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const edit = async (id,data)=> {
    try {
        const toUpdate = {
          cll_order: data?.no,
          cll_name: data?.name,
          cll_type: data?.type,
          cll_timer: data?.timer,
          cll_round: data?.round,
          cll_point: data?.point,
          cll_group: data?.group,
          cll_content: data?.content,
          classroom_id: data?.classroom_id,
          section_id: data?.section_id,
          chapter_id: data?.chapter_id,
          modelvr: data?.modelvr,
          questions: data?.questions ?? [],
          is_active: data?.is_active
        }
        const response = await instance.put(`/classlesson/${id}`,toUpdate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const remove = async (id) => {
  try {
      const response = await instance.delete(`/classlesson/${id}`);
      if(response.status === 200){
          return response.data;
      }else{
          return null
      }
    } catch (error) {
      return null
    }
};

