import React, { useState, useEffect, useMemo } from "react";
import "./Dashboard.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getTeacherDashboard } from "../api/dashboard";
import headerDefinitions from "../tableheaders/learnerdashboard.tableheader.json";
import { useTable, usePagination, useSortBy } from "react-table";
import Table from "react-bootstrap/Table";
import TableHeaderButton from "../components/TableHeaderButton";
import ScorerCard from "../components/ScoreCard";

const StudentDashboard = () => {

  const [dashboardData, setDashboardData] = useState([]);
  const [institution, setInstitution] = useState(null);
  const [topLearner, setTopLearner] = useState([]);
  const [overallPoint, setOverallPoint] = useState(0)
  const [allLearnerPoint, setAllLearnerPoint] = useState(0)
  const [averageLearnerPoint, setAverageLearnerPoint] = useState(0)
  const [learnerPercentage, setLearnerPercentage] = useState(0)
  const [totalLearner, setTotalLearner] = useState(0)

  const [overallPercentage, setOverallPercentage] = useState(0)


  // -------------------- Data Table ----------------------------------------
  const headersData = headerDefinitions.columns;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataTable, setDatatable] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const tableData = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => headersData, [headersData]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useSortBy,
      usePagination
    );

  const toggleRowSelection = (Id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(Id)) {
        return prev.filter((id) => id !== Id);
      } else {
        return [...prev, Id];
      }
    });
  };

  const handleSort = async (columnId) => {
    setSortConfig((prevSortConfig) => {
      let direction = "ASC";
      if (
        prevSortConfig.key === columnId &&
        prevSortConfig.direction === "ASC"
      ) {
        direction = "DESC";
      }
      return { key: columnId, direction };
    });
  };

  const handleNextPage = () => {
    setPageIndex((current) => {
      const nextPageIndex = current + 1;
      setCanNextPage(nextPageIndex < pageCount - 1);
      setCanPreviousPage(true);
      return nextPageIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((current) => {
      const previousPageIndex = Math.max(0, current - 1);
      setCanPreviousPage(previousPageIndex > 0);
      setCanNextPage(true);
      return previousPageIndex;
    });
  };

  // -------------------- Data Table ----------------------------------------

  const fetchData = async () => {
    try {
      const response = await getTeacherDashboard();
      if (response) {

        setDatatable(response?.data);
        setTopLearner(response?.top3LearnerByPoint)
        setInstitution(response?.institution)
        setOverallPoint(response?.overallPoint)
        setAllLearnerPoint(response?.allLearnerPoint)
        setAverageLearnerPoint(response?.averageLearnerPoint)
        setLearnerPercentage(response?.learnerPercentage)
        setTotalLearner(response?.totalLearner)



        const overall = response?.overallPoint > 0 ? ((response?.allLearnerPoint / response?.overallPoint) * 100).toFixed(2) : 0;
        setOverallPercentage(overall)

      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <h2>จัดอันดับคะแนนนักเรียน</h2>
        <p>
          {institution?.ins_name} | {institution?.ins_detail}
        </p>

        <div className="container-fluid p-0 m-0">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="top-scorers">
                {topLearner.map((learner, index) => {
                  // Calculate percentage safely, default to 0 if there's an error
                  const total_point = learner?.totalPoint ?? 0
                  const percentage =
                    overallPoint > 0
                      ? ((total_point / overallPoint) * 100).toFixed(2)
                      : 0;

                  return (
                    <ScorerCard
                      key={learner?.user_code} 
                      name={learner?.fullname} 
                      code={learner?.user_code} 
                      point={total_point} 
                      rank={index + 1} 
                      percentage={percentage} 
                    />
                  );
                })}
              </div>

              <h4>
              อันดับคะเเนนนักเรียนทั้งหมด{" "}
                <span style={{ float: "right" }}>
                นักเรียนทั้งหมด {totalLearner ?? 0} คน
                </span>
              </h4>
              <div style={{ overflow: "auto" }}>
                <Table
                  striped
                  bordered
                  {...getTableProps()}
                  className="table text-center"
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr key={"head"} {...headerGroup.getHeaderGroupProps()}>
                        <th>ลำดับ</th>
                        {headerGroup.headers.map((column) => {
                          const { key, ...rest } = column.getHeaderProps(); // Separate key from other props
                          return (
                            <th key={key} {...rest}>
                              {column.render("Header")}
                              {/* Add a clickable sort icon */}
                              <TableHeaderButton
                                column={column}
                                sortConfig={sortConfig}
                                handleSort={handleSort}
                              />
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    <tr className="space-table"></tr>
                    {rows.length > 0 ? (
                      rows.map((row, rowIndex) => {
                        prepareRow(row);
                        const actualIndex =
                          pageIndex * rowsPerPage + rowIndex + 1;
                        const isSelected = selectedRowIds.includes(
                          row.original.id
                        );

                        const { key, ...rest } = row.getRowProps();

                        return (
                          <tr
                            key={key} // Pass key directly here
                            {...rest}
                            onClick={() => toggleRowSelection(row.original.id)}
                            className={isSelected ? "selected-row" : ""}
                            style={
                              isSelected ? { backgroundColor: "#f0f0f0" } : {}
                            }
                          >
                            <td>{actualIndex}</td>
                            <td>{row.original?.user_code ?? ""}</td>
                            <td>{row.original?.fullname ?? ""}</td>
                            <td>{row.original?.totalPoint ?? ""}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={100} style={{ textAlignLast: "center" }}>
                          ไม่พบข้อมูล
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>


            <div className="col-lg-4 col-md-12">
                <div className="row">
                  {/* Card for Overall Score */}
                  <div className="col-lg-12">
                    <div className="std-card">
                      <div className="std-card-body">
                        <h5 className="std-card-title">คะเนนเฉลี่ยการเรียนรวมทั้งหมด</h5>
                        <div className="mb-3" style={{paddingInline:'20%'}}>
                        <CircularProgressbar
                          value={overallPercentage}
                          text={`${overallPercentage}%`}
                          styles={buildStyles({
                            textColor: '#000',
                            pathColor: '#BEB1FF',
                            trailColor: '#eee',
                            textSize: '16px',  // Adjust size as needed
                          })}
                          className="circular-progressbar"
                        />
                        </div>
                        <p>คะแนนบทเรียนทั้งหมด <span className="text-primary">{overallPoint} คะแนน</span></p>
                        <p>คะแนนรวมผู้เรียนทั้งหมด <span className="text-primary">{allLearnerPoint} คะแนน</span></p>
                        <p>คะแนนเฉลี่ยผู้เรียนทั้งหมด <span className="text-primary">{averageLearnerPoint} %</span></p>
                      </div>
                    </div>
                  </div>

                  {/* Card for Scores Breakdown */}
                  {/* <div className="col-lg-12">
                    <div className="std-card">
                      <div className="std-card-body text-center">
                        <h5 className="std-card-title">จำนวนผู้ใช้งาน</h5>
                        <div className="d-flex justify-content-around">
                          <div className="text-center">
                            <div style={{paddingInline:'10%'}}>
                              <CircularProgressbar
                                value={overallTeacherPercentage}
                                text={`${overallTeacherPercentage}%`}
                                styles={buildStyles({
                                  textColor: '#000',
                                  pathColor: '#BEB1FF',
                                  trailColor: '#eee',
                                })}
                              />
                              <p>ผู้สอน</p>
                            </div>
                          </div>

                          <div className="text-center">
                            <div style={{paddingInline:'10%'}}>
                              <CircularProgressbar
                                value={overallLearnerPercentage}
                                text={`${overallLearnerPercentage}%`}
                                styles={buildStyles({
                                  textColor: '#000',
                                  pathColor: '#a3e0a7',
                                  trailColor: '#eee',
                                })}
                              />
                            </div>
                            <p>ผู้เรียน</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
