import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {
  showCreateDialog,
  showEditDialog,
  showErrorDialog,
  showSuccessDialog,
} from "../components/Dialog";
import { create, edit, getByID } from "../api/question.api";

const TestManageActoion = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const method = urlParams.get("method");
  const navigate = useNavigate();
  const { id } = useParams();
  const [isView, setIsView] = useState(false);
  const [formData, setFormData] = useState({
    que_name: "",
    que_answer1: "",
    que_answer2: "",
    que_answer3: "",
    que_answer4: "",
    que_answer: "",
    que_image: null,
    que_answer1_image: null,
    que_answer2_image: null,
    que_answer3_image: null,
    que_answer4_image: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleAnswerChange = (e) => {
    setFormData({ ...formData, que_answer: e.target.value });
  };


  const isFormValid = () => {
    const {
      que_name,
      que_answer1,
      que_answer2,
      que_answer3,
      que_answer4,
      que_answer,
    } = formData;

    // Check if any of the fields are empty
    if (
      !que_name ||
      !que_answer1 ||
      !que_answer2 ||
      !que_answer3 ||
      !que_answer4 ||
      !que_answer
    ) {
      showErrorDialog("กรุณากรอกข้อมูลให้ครบถ้วน");
      return false;
    }

    // If all validations pass
    return true;
  };

  const createTestData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await create(formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(`/test-manage`);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/test-manage");
          }
        });
      }
    } catch (error) {
      console.log(error)
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/test-manage");
        }
      });
    }
  };

  const updateTestData = async (id) => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await edit(id, formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(`/test-manage`);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/test-manage");
          }
        });
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/test-manage");
        }
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getByID(id);
        if (response) {
          setFormData({
            que_name: response?.que_name,
            que_answer1: response?.que_ans_1,
            que_answer2: response?.que_ans_2,
            que_answer3: response?.que_ans_3,
            que_answer4: response?.que_ans_4,
            que_answer: response?.que_ans,
          });
        } else {
          showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล User").then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate("/test-manage");
            }
          });
        }
      } catch (error) {
        showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล User").then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/test-manage");
          }
        });
      }
    };

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate("/test-manage");
    }

    if (method === "view" || method === "edit") {
      if (!id) {
        navigate("/test-manage");
      }
      fetchData();
    }

    if (method === "view") {
      setIsView(true);
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <h4 className="px-3 mb-2">แบบทดสอบ | จัดการแบบทดสอบ</h4>
        <h5 className="px-3 mb-2 text-header">จัดการแบบทดสอบ</h5>
        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              คำถาม
            </span>
          </Col>
          <Col xs={12} md={6}>
            <input
              disabled={isView}
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.que_name}
              name="que_name"
              id="que_name"
              onChange={handleChange}
            />
          </Col>

          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ภาพประกอบ
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              disabled={isView}
              type="file"
              className="inp-file"
              name="que_image"
              id="que_image"
              onChange={handleImageChange}
              accept="image/*"
            />
          </Col>

        </Row>

        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              คำตอบข้อ 1
            </span>
          </Col>
          <Col xs={12} md={6}>
            <input
              disabled={isView}
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.que_answer1}
              name="que_answer1"
              id="que_answer1"
              onChange={handleChange}
            />
          </Col>

          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ภาพประกอบ
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              disabled={isView}
              type="file"
              className="inp-file"
              name="que_answer1_image"
              id="que_answer1_image"
              onChange={handleImageChange}
              accept="image/*"
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              คำตอบข้อ 2
            </span>
          </Col>
          <Col xs={12} md={6}>
            <input
              disabled={isView}
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.que_answer2}
              name="que_answer2"
              id="que_answer2"
              onChange={handleChange}
            />
          </Col>
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ภาพประกอบ
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              disabled={isView}
              type="file"
              className="inp-file"
              name="que_answer2_image"
              id="que_answer2_image"
              onChange={handleImageChange}
              accept="image/*"
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              คำตอบข้อ 3
            </span>
          </Col>
          <Col xs={12} md={6}>
            <input
              disabled={isView}
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.que_answer3}
              name="que_answer3"
              id="que_answer3"
              onChange={handleChange}
            />
          </Col>
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ภาพประกอบ
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              disabled={isView}
              type="file"
              className="inp-file"
              name="que_answer3_image"
              id="que_answer3_image"
              onChange={handleImageChange}
              accept="image/*"
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              คำตอบข้อ 4
            </span>
          </Col>
          <Col xs={12} md={6}>
            <input
              disabled={isView}
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.que_answer4}
              name="que_answer4"
              id="que_answer4"
              onChange={handleChange}
            />
          </Col>
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ภาพประกอบ
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              disabled={isView}
              type="file"
              className="inp-file"
              name="que_answer4_image"
              id="que_answer4_image"
              onChange={handleImageChange}
              accept="image/*"
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5 align-self-center">
          <Col xs={4} md={"auto"} className="align-self-center">
            <label className="fontLight font-size03 colorGray01 wh-sp">
              คำตอบที่ถูกต้อง{" "}
            </label>
          </Col>

          <Col sm={12} md={6} className="align-self-center px-5">
            <p className="wh-sp">
              <Form.Check
                disabled={isView}
                className="px-5"
                inline
                type="radio"
                label="คำตอบข้อ 1"
                name="answerGroup"
                id="answer1"
                value="1"
                checked={formData.que_answer === "1"}
                onChange={handleAnswerChange}
              />
              <Form.Check
                disabled={isView}
                inline
                className="px-5"
                type="radio"
                label="คำตอบข้อ 2"
                name="answerGroup"
                id="answer2"
                value="2"
                checked={formData.que_answer === "2"}
                onChange={handleAnswerChange}
              />
              <Form.Check
                disabled={isView}
                inline
                className="px-5"
                type="radio"
                label="คำตอบข้อ 3"
                name="answerGroup"
                id="answer3"
                value="3"
                checked={formData.que_answer === "3"}
                onChange={handleAnswerChange}
              />
              <Form.Check
                disabled={isView}
                inline
                className="px-5"
                type="radio"
                label="คำตอบข้อ 4"
                name="answerGroup"
                id="answer4"
                value="4"
                checked={formData.que_answer === "4"}
                onChange={handleAnswerChange}
              />
            </p>
          </Col>
        </Row>

        <Row className="mt-3 px-5">
          <Col xs={6} className="d-flex justify-content-start">
            <button
              className="btn-cancel m-1 mt-5 flex-1"
              onClick={() => {
                navigate("/test-manage");
              }}
            >
              ยกเลิก
            </button>{" "}
            {!isView ? (
              <button
                className="btn-save m-1 mt-5"
                onClick={(e) => {
                  method === "create" ? createTestData() : updateTestData(id);
                }}
              >
                บันทึก
              </button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TestManageActoion;
