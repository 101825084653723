import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from '../contexts/UserContext';
import './Header.css';
import helpers from '../helpers/function.helper';

const Header = ({ onToggleSidebar, isSidebarCollapsed }) => {
  const { user } = useUserContext();
  const initial = user?.name ? user.name.charAt(0).toUpperCase() : '';

  return (
    <nav className={`main-header navbar navbar-expand navbar-white navbar-light ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" onClick={onToggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </a>
        </li>

      </ul>
      <ul className="navbar-nav ml-auto user-info-container">
        <li className="nav-item d-none d-sm-inline-block">
          <div className="user-info text-right">
            <span className="nav-link">{user?.name}</span>
            <span className="nav-link">{helpers.getRoleName(user?.role?.name)}</span>
          </div>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <div className="user-avatar">{initial}</div>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
