import React from 'react';
import './ScoreCard.css'

const ScorerCard = ({ rank, percentage, name, code, point }) => {
  const initials = name?.[0] ?? '';
  return (
    <div className={`scorer-card-${rank}`}>
      <div className="scorer-header">
        {/* Display dynamic rank and percentage */}
        <h3>สูงสุดอันดับ {rank}</h3>
        <p className="rank ml-2">{percentage}%</p>
      </div>
      <div className="scorer-body">
        <div className="scorer-avatar">{initials}</div>
        <div className="scorer-details">
          <p style={{ flex: 2 }}>{name ?? ""}</p>
          <p style={{ flex: 1 }}>ID: {code ?? ""}</p>
          <p style={{ flex: 1 }}>Point: {point ?? 0}</p>
        </div>
      </div>
    </div>
  );
};

export default ScorerCard;
