import spinner from '../assets/spinner.gif';
import './Loading.css';

const LoadingComponent = () => (
  <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '200px'}}>
    <img src={spinner} alt="Loading..." />
  </div>
);

export default LoadingComponent;
