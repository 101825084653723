import React, {useState, useEffect, useMemo} from "react";
import "./Dashboard.css";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { getAdminDashboard } from "../api/dashboard";
import headerDefinitions from "../tableheaders/admindashboard.tableheader.json";
import { useTable, usePagination, useSortBy } from "react-table";
import Table from "react-bootstrap/Table";
import TableHeaderButton from "../components/TableHeaderButton";
import InstitutionCard from "../components/InstitutionCard";

const Dashboard = () => {
  const chart1Details = [
    { label: 'ผู้เรียน', value: '40 คน' },
    { label: 'วิชา', value: 'รถยนต์ไฟฟ้า EV' },
    { label: 'คะแนน', value: '100%' },
  ];

  const chart2Details = [
    { label: 'แบบทดสอบ', value: '70%' },
    { label: 'การบ้าน', value: '70%' },
  ];

  
  const [dashboardData, setDashboardData] = useState([]);
  const [topIns, setTopIns] = useState([]);
  const [overallPercentage, setOverallPercentage] = useState(0)
  const [overallTeacherPercentage, setOverallTeacherPercentage] = useState(0)
  const [overallLearnerPercentage, setOverallLearnerPercentage] = useState(0)


    // -------------------- Data Table ----------------------------------------
    const headersData = headerDefinitions.columns;
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
    const [pageCount, setPageCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dataTable, setDatatable] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [canNextPage, setCanNextPage] = useState(true);
    const [canPreviousPage, setCanPreviousPage] = useState(false);
    const tableData = useMemo(() => dataTable, [dataTable]);
    const columns = useMemo(() => headersData, [headersData]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
  
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data: tableData,
        },
        useSortBy,
        usePagination
      );
  
    const toggleRowSelection = (Id) => {
      setSelectedRowIds((prev) => {
        if (prev.includes(Id)) {
          return prev.filter((id) => id !== Id);
        } else {
          return [...prev, Id];
        }
      });
    };
  
    const handleSort = async (columnId) => {
      setSortConfig((prevSortConfig) => {
        let direction = "ASC";
        if (
          prevSortConfig.key === columnId &&
          prevSortConfig.direction === "ASC"
        ) {
          direction = "DESC";
        }
        return { key: columnId, direction };
      });
    };
  
    const handleNextPage = () => {
      setPageIndex((current) => {
        const nextPageIndex = current + 1;
        setCanNextPage(nextPageIndex < pageCount - 1);
        setCanPreviousPage(true);
        return nextPageIndex;
      });
    };
  
    const handlePreviousPage = () => {
      setPageIndex((current) => {
        const previousPageIndex = Math.max(0, current - 1);
        setCanPreviousPage(previousPageIndex > 0);
        setCanNextPage(true);
        return previousPageIndex;
      });
    };
  
    // -------------------- Data Table ----------------------------------------

    const fetchData = async () => {
      try {
        const response = await getAdminDashboard()
        if(response) {
          
          setDashboardData(response)
          setDatatable(response?.data)
          setTopIns(response?.top3InstitutionsByLicenses)

          const overall = response?.overallTotalLicenses > 0 ? ((response?.overallUsedLicenses / response?.overallTotalLicenses) * 100).toFixed(2) : 0;
          setOverallPercentage(overall)

          const overallTeacher = response?.overallTotalLicenses > 0 ? ((response?.overallTeacherCount / response?.overallTotalLicenses) * 100).toFixed(2) : 0;
          setOverallTeacherPercentage(overallTeacher)

          const overallLearner = response?.overallTotalLicenses > 0 ? ((response?.overallLearnerCount / response?.overallTotalLicenses) * 100).toFixed(2) : 0;
          setOverallLearnerPercentage(overallLearner)

        }
      } catch (error) {
        console.log(error)
      }
    }
    useEffect(() => {
      fetchData();
    }, [pageIndex, rowsPerPage, sortConfig]);

  return (
      <div className="container-fluid">
        <div className="box-white">
          <h2>แดชบอร์ด</h2>
          <h4 className="text-header"> อันดับผู้ใช้งานสูงสุด </h4>

          <div className="container-fluid p-0 m-0">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="top-scorers">
       
                    {topIns.map((institution, index) => {
                      // Calculate percentage safely, default to 0 if there's an error
                      const totalLicenses = institution?.totalLicenses || 0;
                      const usedLicenses = institution?.usedLicenses || 0;
                      const percentage = totalLicenses > 0 ? ((usedLicenses / totalLicenses) * 100).toFixed(2) : 0;

                      return (
                        <InstitutionCard
                          key={institution.ins_code}  // unique key based on ins_code
                          institution={institution}   // pass institution object
                          rank={index + 1}            // rank based on index
                          percentage={percentage}     // calculated percentage
                        />
                      );
                    })}
               
                </div>

                <h4>อันดับการใช้งานใบอนุญาต <span style={{float:'right'}}>ใบอนุญาตทั้งหมด {dashboardData?.overallTotalLicenses ?? 0}</span></h4> 
                <div style={{ overflow: "auto" }}>
            <Table
              striped
              bordered
              {...getTableProps()}
              className="table text-center"
              style={{ borderCollapse: "collapse" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={"head"} {...headerGroup.getHeaderGroupProps()}>
                    <th>ลำดับ</th>
                    {headerGroup.headers.map((column) => {
                      const { key, ...rest } = column.getHeaderProps(); // Separate key from other props
                      return (
                        <th key={key} {...rest}>
                          {column.render("Header")}
                          {/* Add a clickable sort icon */}
                          <TableHeaderButton
                            column={column}
                            sortConfig={sortConfig}
                            handleSort={handleSort}
                          />
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                <tr className="space-table"></tr>
                {rows.length > 0 ? (
                  rows.map((row, rowIndex) => {
                    prepareRow(row);
                    const actualIndex = pageIndex * rowsPerPage + rowIndex + 1;
                    const isSelected = selectedRowIds.includes(row.original.id);

                    const { key, ...rest } = row.getRowProps();

                    return (
                      <tr
                        key={key} // Pass key directly here
                        {...rest}
                        onClick={() => toggleRowSelection(row.original.id)}
                        className={isSelected ? "selected-row" : ""}
                        style={isSelected ? { backgroundColor: "#f0f0f0" } : {}}
                      >
                        <td>{actualIndex}</td>
                        <td>{row.original?.ins_code ?? ""}</td>
                        <td>{row.original?.ins_name ?? ""}</td>
                        <td>{row.original?.usedLicenses ?? ""}</td>
                        <td>{row.original?.totalLicenses ?? ""}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan={100} style={{ textAlignLast: "center" }}>
                      ไม่พบข้อมูล
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div
            className="py-3 mx-2"
            style={{ display: "flex", justifyContent: "right" }}
          >
            {/* Pagination */}
            {/* <div className="mt-2">
              <span>
                {`${pageIndex * rowsPerPage + 1} - ${
                  (pageIndex + 1) * rowsPerPage
                } จาก ${pageCount} `}
              </span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handlePreviousPage}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <span> {pageIndex + 1}</span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handleNextPage}
                disabled={!canNextPage}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
              <span>{Math.ceil(pageCount / rowsPerPage)}</span>
            </div> */}
          </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="row">
                  {/* Card for Overall Score */}
                  <div className="col-lg-12">
                    <div className="std-card">
                      <div className="std-card-body text-center">
                        <h5 className="std-card-title text-center">จำนวนการใช้งานใบอนุญาต</h5>
                        <div className="mb-3" style={{paddingInline:'20%'}}>
                        <CircularProgressbar
                          value={overallPercentage}
                          text={`${overallPercentage}%`}
                          styles={buildStyles({
                            textColor: '#000',
                            pathColor: '#BEB1FF',
                            trailColor: '#eee',
                            textSize: '16px',  // Adjust size as needed
                          })}
                          className="circular-progressbar"
                        />
                        </div>
                        <p>ใบอนุญาตทั้งหมด <span className="text-primary">{dashboardData?.overallTotalLicenses}</span></p>
                        <p>ใบอนุญาตที่ถูกใช้งาน <span className="text-primary">{dashboardData?.overallUsedLicenses}</span></p>
                      </div>
                    </div>
                  </div>

                  {/* Card for Scores Breakdown */}
                  <div className="col-lg-12">
                    <div className="std-card">
                      <div className="std-card-body text-center">
                        <h5 className="std-card-title">จำนวนผู้ใช้งาน</h5>
                        <div className="row">
                          <div className="text-center col-6">
                            <div style={{paddingInline:'10%'}}>
                              <CircularProgressbar
                                value={overallTeacherPercentage}
                                text={`${overallTeacherPercentage}%`}
                                styles={buildStyles({
                                  textColor: '#000',
                                  pathColor: '#BEB1FF',
                                  trailColor: '#eee',
                                  textSize: '16px',
                                })}
                                className="circular-progressbar"
                              />
                              <p className="my-2">ผู้สอน <span className="text-primary">{dashboardData?.overallTeacherCount}</span></p>
                            </div>
                          </div>

                          {/* Homework Score */}
                          <div className="text-center col-6">
                            <div style={{paddingInline:'10%'}}>
                              <CircularProgressbar
                                value={overallLearnerPercentage}
                                text={`${overallLearnerPercentage}%`}
                                styles={buildStyles({
                                  textColor: '#000',
                                  pathColor: '#BEB1FF',
                                  trailColor: '#eee',
                                  textSize: '16px',
                                })}
                                className="circular-progressbar"
                              />
                            </div>
                            <p className="my-2">ผู้เรียน <span className="text-primary">{dashboardData?.overallLearnerCount}</span></p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Dashboard;
