import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getList = async (query)=> {
    try {
        const response = await instance.get(`/reward-category`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`/reward-category/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (formData) => {
    const data = new FormData();
  
    // Append form fields
    for (const [key, value] of Object.entries(formData)) {
        data.append(key, value);
    }

    try {
      const response = await instance.post(`/reward-category`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error:', error); // Log the error for debugging
      return null;
    }
  };

export const edit = async (id, formData) => {
    try {
    const data = new FormData();
    for (const [key, value] of Object.entries(formData)) {
        data.append(key, value);
    }
        const response = await instance.put(`/reward-category/${id}`,data,{
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const remove = async (id) => {
  try {
      const response = await instance.delete(`/reward-category/${id}`);
      if(response.status === 200){
          return response.data;
      }else{
          return null
      }
    } catch (error) {
      return null
    }
};
  
