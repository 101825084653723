import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";
import {
  showCreateDialog,
  showEditDialog,
  showErrorDialog,
  showSuccessDialog,
} from "../components/Dialog";
import {
  create,
  edit,
  getByID,
  getCateList,
  getClassList,
} from "../api/reward.api";
import Select from "react-select";
import LoadingComponent from "../components/Loading";
import ReactDatePicker from "react-datepicker";

const TeacherRewardManageAction = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const method = urlParams.get("method");
  const shouldNavigate = "/rewardmanage";

  const [isView, setIsView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rewardCategoryOptions, setRewardCategoryOptions] = useState([]);
  const [classroomOptions, setClassroomOptions] = useState([]);
  const [formData, setFormData] = useState({
    rew_name: "",
    rec_id: "",
    classroom_id: "",
    is_active: false,
    is_limit: false,
    rew_count: "",
    rew_point: "",
    rew_start: "",
    rew_end: "",
  });

  const isFormValid = () => {
    const {
      rew_name,
      rec_id,
      classroom_id,
      rew_start,
      rew_end,
      rew_count,
      rew_point,
      is_limit
    } = formData;
    // Check if required fields are filled
    if (!rew_name || !rec_id || !classroom_id || !rew_point) {
      showErrorDialog("กรุณากรอกข้อมูลให้ครบถ้วน");
      return false;
    }

    if(is_limit && !rew_count ) {
      showErrorDialog("กรุณากรอกข้อมูลให้ครบถ้วน");
      return false;
    }

    // Ensure ready date is before expired date
    if (new Date(rew_start) >= new Date(rew_end)) {
      showErrorDialog("วันที่เริ่มต้นต้องน้อยกว่าวันหมดอายุ");
      return false;
    }

    // If all fields are valid
    return true;
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIsActiveChange = (e) => {
    const { name, value } = e.target;
    if (name === "isActiveGroup") {
      setFormData({ ...formData, is_active: value === "true" });
    } else if (name === "isLimitGroup") {
      setFormData({ ...formData, is_limit: value === "true" });
    }
  };

  const handleDateChange = (date, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };

  const createRewardData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await create(formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(shouldNavigate);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(shouldNavigate);
        }
      });
    }
  };

  const updateRewardData = async (id) => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await edit(id, formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(shouldNavigate);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(shouldNavigate);
        }
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getByID(id);
        if (response) {
          setFormData({
            rew_name: response?.rew_name,
            classroom_id: response?.classroom?.cla_id,
            rec_id: response?.reward_category?.rec_id,
            rew_count: response?.rew_count,
            rew_point: response?.rew_point,
            rew_start: response?.rew_start,
            rew_end: response?.rew_end,
            is_active: response?.is_active,
            is_limit: response?.is_limit,
          });
        } else {
          showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then(
            (result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(shouldNavigate);
              }
            }
          );
        }
      } catch (error) {
        showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    };

    const fetchDropdownData = async () => {
      try {
        const category = await getCateList();
        if (category) {
          const categoryOptions = category.data.map((c) => ({
            label: c.rec_name,
            value: c.rec_id,
          }));
          setRewardCategoryOptions(categoryOptions);
        } else {
          showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then(
            (result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(shouldNavigate);
              }
            }
          );
        }
        // Classroom
        const classroom = await getClassList();
        if (classroom) {
          const classroomOp = classroom.data.map((c) => ({
            label: c.cla_name,
            value: c.cla_id,
          }));
          setClassroomOptions(classroomOp);
        } else {
          showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Classroom").then(
            (result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(shouldNavigate);
              }
            }
          );
        }
      } catch (error) {
        showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    };

    fetchDropdownData();

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate(shouldNavigate);
    }

    if (method === "view" || method === "edit") {
      fetchData();
    }

    if (method === "view") {
      setIsView(true);
    }

    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div className="container-fluid">
          <div className="box-white">
            <h4 className="px-3 mb-2">ของรางวัล</h4>
            <h5 className="px-3 mb-2 text-header">จัดการข้อมูล ของรางวัล</h5>
            <Row className="mt-3 px-5">
              <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ชื่อของรางวัล
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.rew_name}
                  name="rew_name"
                  id="rew_name"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5">
              <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ประเภทของรางวัล
                </span>
              </Col>
              <Col xs={12} md={4}>
                <Select
                  isDisabled={isView}
                  name="rec_id"
                  value={rewardCategoryOptions.find(
                    (option) => option.value === formData.rec_id
                  )}
                  options={rewardCategoryOptions}
                  onChange={handleSelectChange}
                  placeholder="Please select"
                />
              </Col>

              <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ห้องเรียน
                </span>
              </Col>
              <Col xs={12} md={4}>
                <Select
                  isDisabled={isView}
                  name="classroom_id"
                  value={classroomOptions.find(
                    (option) => option.value === formData.classroom_id
                  )}
                  options={classroomOptions}
                  onChange={handleSelectChange}
                  placeholder="Please select"
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5 align-self-center">
              <Col xs={"auto"} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  จำกัดสิทธิ์{" "}
                </label>
              </Col>

              <Col sm={12} md={4} className="align-self-center px-5">
                <p className="wh-sp">
                  <Form.Check
                    disabled={isView}
                    className="px-5"
                    inline
                    type="radio"
                    label="ใช่"
                    name="isLimitGroup"
                    id="Active"
                    value="true"
                    checked={formData.is_limit === true}
                    onChange={handleIsActiveChange}
                  />
                  <Form.Check
                    disabled={isView}
                    inline
                    type="radio"
                    label="ไม่ใช่"
                    name="isLimitGroup"
                    id="Inactive"
                    value="false"
                    checked={formData.is_limit === false}
                    onChange={handleIsActiveChange}
                  />
                </p>
              </Col>
            </Row>

            <Row className="mt-3 px-5">
              <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  คะแนน
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.rew_point}
                  name="rew_point"
                  id="rew_point"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    const key = event.key;
                    if (
                      !/^\d$/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Delete"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>

              {formData.is_limit ? (<>
                <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  จำนวนสิทธิ์
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.rew_count}
                  name="rew_count"
                  id="rew_count"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    const key = event.key;
                    if (
                      !/^\d$/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Delete"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>
              </>):(<></>)}
            </Row>

            <Row className="mt-3 px-5">
              <Col xs={4} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  วันที่เริ่มต้น
                </span>
              </Col>
              <Col xs={12} md={4}>
                <ReactDatePicker
                  style={{ display: "block" }}
                  selected={formData.rew_start}
                  onChange={(date) => handleDateChange(date, "rew_start")}
                  disabled={method === "view"}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="วัน/เดือน/ปี"
                  className="form-control"
                />
              </Col>

              <Col xs={4} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  วันที่สิ้นสุด
                </span>
              </Col>
              <Col xs={12} md={4}>
                <ReactDatePicker
                  style={{ display: "block" }}
                  selected={formData.rew_end}
                  onChange={(date) => handleDateChange(date, "rew_end")}
                  disabled={method === "view"}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="วัน/เดือน/ปี"
                  className="form-control"
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5 align-self-center">
              <Col xs={"auto"} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  สถานะ{" "}
                </label>
              </Col>

              <Col sm={12} md={4} className="align-self-center px-5">
                <p className="wh-sp">
                  <Form.Check
                    disabled={isView}
                    className="px-5"
                    inline
                    type="radio"
                    label="ใช้งาน"
                    name="isActiveGroup"
                    id="Active"
                    value="true"
                    checked={formData.is_active === true}
                    onChange={handleIsActiveChange}
                  />
                  <Form.Check
                    disabled={isView}
                    inline
                    type="radio"
                    label="ไม่ใช้งาน"
                    name="isActiveGroup"
                    id="Inactive"
                    value="false"
                    checked={formData.is_active === false}
                    onChange={handleIsActiveChange}
                  />
                </p>
              </Col>
            </Row>

            <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate(shouldNavigate);
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createRewardData()
                        : updateRewardData(id);
                    }}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
export default TeacherRewardManageAction;
