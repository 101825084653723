import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getAdminDashboard = async (query)=> {
    try {
        const response = await instance.get(`/dashboard/admin-dashboard`,{ params: query });
  
        if(response.status === 200){
            const data = response.data || [];
            return data
        }else{
            return null
        }
  
      } catch (error) {
        console.log(error)
        return null
      }
};

export const getTeacherDashboard = async (query)=> {
    try {
        const response = await instance.get(`/dashboard/teacher-dashboard`,{ params: query });
  
        if(response.status === 200){
            const data = response.data || [];
            return data
        }else{
            return null
        }
  
      } catch (error) {
        console.log(error)
        return null
      }
};