import React from 'react';
import './ScoreCard.css'

const InstitutionCard = ({ institution, rank, percentage }) => {
  // Get initials from institution name
  const initials = institution?.ins_name?.[0] ?? '';

  return (
    <div className={`scorer-card-${rank}`}>
      <div className="scorer-header">
        {/* Display dynamic rank and percentage */}
        <h3>สูงสุดอันดับ {rank}</h3>
        <p className="rank ml-2">{percentage}%</p>
      </div>
      <div className="scorer-body">
        <div className="scorer-avatar">{initials}</div>
        <div className="scorer-details">
          <p style={{ flex: 2 }}>{institution?.ins_name ?? ""}</p>
          <p style={{ flex: 1 }}>ID: {institution?.ins_code ?? ""}</p>
          <p style={{ flex: 1 }}>จำนวน: {institution?.usedLicenses} คน</p>
        </div>
      </div>
    </div>
  );
};

export default InstitutionCard;
