import React, { useEffect,  useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getByID, create, edit } from "../api/instutition.api";
import withLoading from "../hoc/withLoading";
import { showCreateDialog, showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";
import ImageUpload from "../components/ImageUpload";
import helpers from "../helpers/function.helper";
import EnumRoles from "../constants/EnumRoles";

const InstitutionAction = ({user}) => {
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [imagePreviews, setImagePreviews] = useState([]);
  const [bannerPreviews, setBannerPreviews] = useState([]);
  const [removedImages, setRemovedImages] = useState([]); // Track removed images
  const [newImages, setNewImages] = useState([]); // Newly uploaded images
  const [bannerImages, setBannerImages] = useState([]); 
  const [method, setMethod] = useState("");

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    code: "",
    contact: "",
    tel: "",
    address: "",
    detail: "",
    map: "",
    staff_username:"",
    staff_password:"",
    is_active: true,
  });

  const isFormValid = () => {
    const { code, name, tel, address, contact } = formData;
  
    // Check if any of the fields are empty
    if (!code || !name ) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false;
    }

    return true;
  };

  const handleIsActiveChange = (e) => {
    setFormData({ ...formData, is_active: e.target.value === "true" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (uploadedFiles) => {
    setNewImages([...newImages, ...uploadedFiles]);
  };

  const handleBannerUpload = (uploadedFiles) => {
    setBannerImages([...bannerImages, ...uploadedFiles]);
  };

  const fetchData = async (id) => {
    try {
      setLoading(true);
      const response = await getByID(id);
      if (response) {
        setFormData({
          id: response.ins_id ?? "",
          code: response.ins_code ?? "",
          name: response.ins_name ?? "",
          contact: response.ins_contact ?? "",
          detail: response.ins_detail ?? "",
          map: response.ins_map ?? "",
          tel: response.ins_tel ?? "",
          address: response.ins_address ?? "",
          is_active: response.is_active ?? false,
          staff_username: response?.staff_username,
          staff_password: response?.staff_password,
        });

        if (response?.ins_logo) {
          setImagePreviews([{
            src: response?.ins_logo,
            name: `image_1`,
            id: 1,
          }])
        }

        if (response?.ins_banner) {
          setBannerPreviews([{
            src: response?.ins_banner,
            name: `image_2`,
            id: 1,
          }])
        }

      } else {
        showErrorDialog('ไม่พบข้อมูล สถาบัน').then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            if(user?.role?.name === EnumRoles.SUPERADMIN){
              navigate("/institutions");
            }
          }
        });
      }
    } catch (error) {
      showErrorDialog('ไม่พบข้อมูล สถาบัน').then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          if(user?.role?.name === EnumRoles.SUPERADMIN){
            navigate("/institutions");
          }
        }
      });
    } finally {
      setLoading(false)
    }
  };

  const createInstitutionData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {

      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      const response = await create(formData, newImages, bannerImages, removedImages);
      if (response) {
        showSuccessDialog('เพิ่มข้อมูลสำเร็จ')
        if(user?.role?.name === EnumRoles.SUPERADMIN){
          navigate("/institutions");
        }

      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            if(user?.role?.name === EnumRoles.SUPERADMIN){
              navigate("/institutions");
            }
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          if(user?.role?.name === EnumRoles.SUPERADMIN){
            navigate("/institutions");
          }
        }
      });
    } finally{
      setLoading(false)
    }
  };

  const updateInstitutionData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      const response = await edit(formData, newImages, bannerImages, removedImages);
      if (response) {
        showSuccessDialog('แก้ไขข้อมูลสำเร็จ')
        if(user?.role?.name === EnumRoles.SUPERADMIN){
          navigate("/institutions");
        }

      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            if(user?.role?.name === EnumRoles.SUPERADMIN){
              navigate("/institutions");
            }
          }
        });
      }
    } catch (error) {
      console.log(error)
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          if(user?.role?.name === EnumRoles.SUPERADMIN){
            navigate("/institutions");
          }
        }
      });
    } finally{
      setLoading(false)
    }
  };

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const method = urlParams.get("method");
    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate("/institutions");
    }

    if (method === "view" || method === "edit") {
      fetchData(id);
    }

    if (method === "view") {
      setIsView(true);
    }

    setMethod(method);
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h4 className="px-3 mb-2">สถาบัน | จัดการข้อมูลสถาบัน</h4>
          <h5 className="px-3 mb-2 text-header">จัดการข้อมูลสถาบัน</h5>

          <Row className="mt-3 px-5">
            <Col xs={4} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                รหัสสถาบัน
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.code}
                name="code"
                id="code"
                disabled={method !== 'create'}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={4} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                ชื่อสถาบัน
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.name}
                name="name"
                id="name"
                disabled={isView}
                onChange={handleChange}
              />
            </Col>

            <Col xs={4} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                ผู้ดูแล
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.contact}
                name="contact"
                id="contact"
                disabled={isView}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3 px-5">
            <Col xs={4} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                เบอร์ติดต่อ
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.tel}
                name="tel"
                id="tel"
                disabled={isView}
                onChange={handleChange}
              />
            </Col>

            <Col xs={4} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                ที่อยู่
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.address}
                name="address"
                id="address"
                disabled={isView}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={4} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                ลิงก์แผนที่
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.map}
                name="map"
                id="map"
                disabled={isView}
                onChange={handleChange}
              />
            </Col>

            <Col xs={4} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                รายละเอียด
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.detail}
                name="detail"
                id="detail"
                disabled={isView}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
              <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  โลโก้สถาบัน
                </span>
              </Col>
              <Col xs={12} md={4}>
                {isView ? (
                  <div className="image-preview-container">
                    {imagePreviews.map((preview, index) => (
                      <div key={index + 1} className="image-preview">
                        <img
                          src={preview.src}
                          alt={preview.name}
                          width={'150px'}
                          height={'auto'}
                          className="image-preview-thumb"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <ImageUpload
                    onUpload={handleImageUpload}
                    datDetailImage={() => {}}
                    dtStatusClear={false}
                    initialPreviews={imagePreviews} 
                    maxImages={1}
                  />
                )}
              </Col>

              <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  แบนเนอร์สถาบัน
                </span>
              </Col>
              <Col xs={12} md={4}>
                {isView ? (
                  <div className="image-preview-container">
                    {bannerPreviews.map((preview, index) => (
                      <div key={index + 1} className="image-preview">
                        <img
                          src={preview.src}
                          alt={preview.name}
                          width={'150px'}
                          height={'auto'}
                          className="image-preview-thumb"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <ImageUpload
                    onUpload={handleBannerUpload}
                    datDetailImage={() => {}}
                    dtStatusClear={false}
                    initialPreviews={bannerPreviews} 
                    maxImages={1}
                  />
                )}
              </Col>
            </Row>

            {user?.role?.name === EnumRoles.SUPERADMIN ? (
              <Row className="mt-3 px-5 align-self-center">
                <Col xs={4} md={1} className="align-self-center">
                  <label className="fontLight font-size03 colorGray01 wh-sp">
                    สถานะ{" "}
                  </label>
                </Col>

                <Col sm={12} md={4} className="align-self-center px-5 mt-1">
                  <p className="wh-sp">
                    <Form.Check
                      disabled={isView}
                      className="px-5 mt-2"
                      inline
                      type="radio"
                      label="ใช้งาน"
                      name="isActiveGroup"
                      id="Active"
                      value="true"
                      checked={formData.is_active === true}
                      onChange={handleIsActiveChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="ไม่ใช้งาน"
                      name="isActiveGroup"
                      id="Inactive"
                      value="false"
                      disabled={isView}
                      checked={formData.is_active === false}
                      onChange={handleIsActiveChange}
                    />
                  </p>
                </Col>
              </Row>
            ):(<></>)}
          <Row className="mt-3 px-5">
            <Col xs={6} className="d-flex justify-content-start">
              <button
                className="btn-cancel m-1 mt-5 flex-1"
                onClick={() => {
                  navigate("/institutions");
                }}
              >
                ยกเลิก
              </button>{" "}
              {!isView ? (
                <button
                  className="btn-save m-1 mt-5"
                  onClick={(e) => {
                    method === "create"
                      ? createInstitutionData()
                      : updateInstitutionData();
                  }}
                >
                  บันทึก
                </button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withLoading(InstitutionAction);
