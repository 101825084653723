import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import { changePassword, edit, getUsersID } from "../api/users.api";
import withLoading from "../hoc/withLoading";
import EnumRoles from "../constants/EnumRoles";
import { showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";

const UserAction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get("user_id");
  const user_type = urlParams.get("user_type");
  const method = urlParams.get("method");
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null)

  const [formData, setFormData] = useState({
    user_code: '',
    fullname: '',
    username: '',
    email: '',
    phone: '',
    institution: '',
  });

  let headerText = "แก้ไขข้อมูลผู้ใช้งาน";
  let navigate_url = ''
  let type = ''
  if (user_type === EnumRoles.TEACHER) {
    headerText = "ผู้สอน | แก้ไขข้อมูลผู้สอน";
    navigate_url = "/teacher-user"
    type = 'ผู้สอน'
  } else if (user_type === EnumRoles.LEARNER) {
    headerText = "ผู้เรียน | แก้ไขข้อมูลผู้เรียน";
    navigate_url = "/learner-user"
    type = 'ผู้เรียน'
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isFormValid = () => {
    const { user_code, fullname, username, email, phone, institution } = formData;
  
    // Check if any of the fields are empty
    if (!user_code || !fullname || !username || !email || !phone || !institution) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false;
    }
  
    // Check if email is valid (basic regex for email validation)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      showErrorDialog('อีเมลไม่ถูกต้อง')
      return false;
    }
  
    // Check if phone number is valid (assuming phone number should be 10 digits, adjust if necessary)
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(phone)) {
      showErrorDialog('เบอร์โทรศัพท์ไม่ถูกต้อง')
      return false;
    }
  
    // If all validations pass
    return true;
  };

  const submitEditUser = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      const response = await edit(user_id,formData);
      if (response) {
        showSuccessDialog('แก้ไขข้อมูลสำเร็จ')
        navigate(navigate_url);
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(navigate_url);
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const shouldNavigate = method !== "edit" || !user_type || !user_id;
    if (shouldNavigate) {
      navigate(navigate_url);
      return;
    }
  
    const fetchData = async () => {
      try {
        const response = await getUsersID(user_id);
        if (response) {
          setUserData(response);
          setFormData({
            user_code: response?.user_code,
            fullname: response?.fullname,
            username: response?.username,
            email: response?.email,
            phone: response?.phone,
            institution: response?.institution?.ins_name,
          })
        } else {
          showErrorDialog('เกิดข้อผิดพลาด ไม่พบข้อมูล User').then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(navigate_url);
            }
          });
        }
      } catch (error) {
        showErrorDialog('เกิดข้อผิดพลาด ไม่พบข้อมูล User').then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(navigate_url);
          }
        });
      }
    };
  
    fetchData();
  }, [method, user_type, user_id, navigate, navigate_url]);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h4 className="px-3 mb-2">{headerText}</h4>
          <h4 className="px-3 mb-2 text-header">แก้ไขข้อมูล{type}</h4>

          <Row className="mt-3 px-5">
            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                รหัสผู้ใช้งาน
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                disabled={true}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.user_code}
                name="user_code"
                id="user_code"
                onChange={handleChange}
              />
            </Col>

            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                ชื่อ-นามสกุล
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                disabled={true}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.fullname}
                name="fullname"
                id="fullname"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                ชื่อผู้ใช้งาน
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                disabled={true}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.username}
                name="username"
                id="username"
                onChange={handleChange}
              />
            </Col>

            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                อีเมล
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.email}
                name="email"
                id="email"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                เบอร์ติดต่อ
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.phone}
                name="phone"
                id="phone"
                onChange={handleChange}
              />
            </Col>

            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                สถาบัน
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                disabled={true}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.institution}
                name="institution"
                id="institution"
                onChange={handleChange}
              />
            </Col>
          </Row>



          <Row className="mt-3 px-5">
            <Col xs={6} className="d-flex justify-content-start">
              <button
                className="btn-cancel m-1 mt-5 flex-1"
                onClick={() => {
                  navigate(navigate_url);
                }}
              >
                ยกเลิก
              </button>{" "}
              <button
                className="btn-save m-1 mt-5"
                onClick={() => submitEditUser()}
              >
                {isLoading ? "กำลังบันทึก..." : "บันทึก"}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withLoading(UserAction);
