import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withLoading from "../hoc/withLoading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EnumRoles from "../constants/EnumRoles";
import { getStaffByID, updateStaffByID } from "../api/instutition.api";
import {
  showEditDialog,
  showErrorDialog,
  showSuccessDialog,
} from "../components/Dialog";

const InstitutionStaff = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const method = urlParams.get("method");
  const { id } = useParams();
  const navigate = useNavigate();
  const navigate_url = "/institutions";

  const [institution, setInstitution] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    phone: "",
    fullname: "",
    role: EnumRoles.STAFF,
    institution_id: id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const isFormValid = () => {
    const { username, email, phone, fullname } = formData;
  
    // Check if any of the fields are empty
    if (!fullname || !username || !email) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false;
    }
  
    // Check if email is valid (basic regex for email validation)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      showErrorDialog('อีเมลไม่ถูกต้อง')
      return false;
    }
  
    // Check if phone number is valid (assuming phone number should be 10 digits, adjust if necessary)
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(phone)) {
      showErrorDialog('เบอร์โทรศัพท์ไม่ถูกต้อง')
      return false;
    }
  
    // If all validations pass
    return true;
  };

  const submitEditUser = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return;
      }

      setLoading(true);
      const response = await updateStaffByID(id, formData);
      if (response) {
        showSuccessDialog("แก้ไขข้อมูลสำเร็จ");
        navigate(navigate_url);
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(navigate_url);
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getStaffByID(id);
        if (response) {
          setFormData({
            username: response?.staff?.username,
            email: response?.staff?.email,
            phone: response?.staff?.phone,
            fullname: response?.staff?.fullname,
          });

          setInstitution(response);
        } else {
          showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล User").then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(navigate_url);
            }
          });
        }
      } catch (error) {
        showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล User").then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(navigate_url);
          }
        });
      }
    };

    if (method !== "edit") {
      navigate(navigate_url);
    }

    if (method === "edit") {
      if (!id) {
        navigate(navigate_url);
      }
      fetchData();
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <h4 className="px-3 mb-2">เจ้าหน้าที่ | จัดการเจ้าหน้าที่</h4>
        <h5 className="px-3 mb-2 text-header">
          จัดการเจ้าหน้าที่ สถาบัน{" "}
          <span className="header-text">{institution?.ins_name}</span>
        </h5>
        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={2} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ชื่อ-นามสกุล
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.fullname}
              name="fullname"
              id="fullname"
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={2} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              เบอร์ติดต่อ
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.phone}
              name="phone"
              id="phone"
              onChange={handleChange}
            />
          </Col>

          <Col xs={"auto"} md={2} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              อีเมล
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.email}
              name="email"
              id="email"
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={2} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ชื่อผู้ใช้งาน
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.username}
              name="username"
              id="username"
              onChange={handleChange}
            />
          </Col>

          <Col xs={"auto"} md={2} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              รหัสผู้ใช้งาน
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              type="password"
              className="inp-login fontLight font-size03"
              value={formData.password}
              name="password"
              id="password"
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5">
            <Col xs={6} className="d-flex justify-content-start">
              <button
                className="btn-cancel m-1 mt-5 flex-1"
                onClick={() => {
                  navigate(navigate_url);
                }}
              >
                ยกเลิก
              </button>{" "}
              <button
                className="btn-save m-1 mt-5"
                onClick={() => submitEditUser()}
              >
                {isLoading ? "กำลังบันทึก..." : "บันทึก"}
              </button>
            </Col>
          </Row>
      </div>
    </div>
  );
};

export default withLoading(InstitutionStaff);
