import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;


export const getList = async (query)=> {
    try {
        const response = await instance.get(`${API_BASE_URL}/institution`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`${API_BASE_URL}/institution/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const getStaffByID = async (id) => {
    try {
        const response = await instance.get(`${API_BASE_URL}/staff/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const updateStaffByID = async (id,formData) => {
    try {
        const toUpdate = {
            fullname: formData?.fullname,
            email: formData?.email,
            phone: formData?.phone,
            username: formData?.username,
            password: formData?.password,
            role: formData?.role,
        }

        const response = await instance.put(`${API_BASE_URL}/staff/${id}`, toUpdate);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (formData, newImages, bannerImages, removedImages) => {
    try {
        const toCreate = {
            ins_code: formData?.code,
            ins_name: formData?.name,
            ins_contact: formData?.contact,
            ins_map: formData?.map,
            ins_address: formData?.address,
            ins_detail: formData?.detail,
            ins_tel: formData?.tel,
            is_active: formData?.is_active
        }

        const data = new FormData();
  
        // Append form fields
        for (const [key, value] of Object.entries(toCreate)) {
            data.append(key, value);
        }
    
        // Append new images
        if (newImages.length > 0) {
            newImages.forEach((file) => {
            data.append('ins_logo', file); // Append each file with the key 'files'
            });
        }

        if (bannerImages.length > 0) {
            bannerImages.forEach((file) => {
            data.append('ins_banner', file); // Append each file with the key 'files'
            });
        }
    
        // Add removed image IDs to the form data if necessary
        if (removedImages.length > 0) {
            const removedIds = removedImages.map(img => img.id);
            data.append('removedImages', JSON.stringify(removedIds));
        }

        const response = await instance.post(`${API_BASE_URL}/institution`,data);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const edit = async (formData, newImages, bannerImages, removedImages) => {
    try {
        const toUpdate = {
            ins_id: formData?.id,
            ins_code: formData?.code,
            ins_name: formData?.name,
            ins_contact: formData?.contact,
            ins_map: formData?.map,
            ins_address: formData?.address,
            ins_detail: formData?.detail,
            ins_tel: formData?.tel,
            is_active: formData?.is_active
        }

        const data = new FormData();
  
        // Append form fields
        for (const [key, value] of Object.entries(toUpdate)) {
            data.append(key, value);
        }
    
        // Append new images
        if (newImages.length > 0) {
            newImages.forEach((file) => {
            data.append('ins_logo', file); // Append each file with the key 'files'
            });
        }

        if (bannerImages.length > 0) {
            bannerImages.forEach((file) => {
            data.append('ins_banner', file); // Append each file with the key 'files'
            });
        }
    
        // Add removed image IDs to the form data if necessary
        if (removedImages.length > 0) {
            const removedIds = removedImages.map(img => img.id);
            data.append('removedImages', JSON.stringify(removedIds));
        }
        const response = await instance.put(`${API_BASE_URL}/institution/${formData?.id}`,data);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        console.log(error)
        return null
      }
}

export const downloadExcel = async (query) => {
    try {
        const response = await instance.get(`${API_BASE_URL}/institution/export`,{ params: query },
            {
              responseType: 'blob', // Important: ensures the response is handled as a Blob for binary data
            }
          );

        if(response.status === 200){
            return response.data
        }else{
            return null
        }
    } catch (error) {
        return null
    }
}

export const getInstitutionTeacher = async (query)=> {
    try {
        const response = await instance.get(`${API_BASE_URL}/institution/teacher`);

        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};