import React, {useEffect, useMemo, useState, useCallback} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import TableHeaderButton from "../components/TableHeaderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faSearch,
  } from "@fortawesome/free-solid-svg-icons";
import headerDefinitions from "../tableheaders/learnerprogress.tableheader.json";
import { useTable, usePagination, useSortBy } from "react-table";
import viewIcon from "../assets/icons/view.png";
import helpers from "../helpers/function.helper";
import { getLearnerProgress } from "../api/classroom.api";
import SidePanelContent from "../components/SidePanelContent";

const LearnerProgress = () => {

    const [keyword, setKeyword] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [selectedSection, setSelectedSection] = useState(null);
    const [showSidePanel, setShowSidePanel] = useState(false);
    const [progressData, setProgressData] = useState({
        cla_id:'',
        cla_name:'',
        lea_code:'',
        lea_name:''
    });

    const [progress, setProgress] = useState([])

    const urlParams = new URLSearchParams(window.location.search);
    const learner_id = urlParams.get("learner_id");
    const classroom_id = urlParams.get("cla_id");

    const handleViewClick = async (section) => {
        const progress = section.chapters.map(item => {
              const progressItem = {
                title: `${section.cls_name} ${item.clc_name}`,
                subsections: [
                  { name: "ก่อนเรียน", progress: parseFloat(item.progress.pretest) },
                  { name: "เนื้อหา", progress: parseFloat(item.progress.learn) },
                  { name: "หลังเรียน", progress: parseFloat(item.progress.posttest) },
                  { name: "การบ้าน", progress: parseFloat(item.progress.homework) }
                ]
              };
              return progressItem
        })


        setProgress(progress)
        setSelectedSection(section);
        setShowSidePanel(true);
      };
    
      const handleClose = () => {
        setShowSidePanel(false);
        setSelectedSection(null);
      };

// -------------------- Data Table ----------------------------------------
  const headersData = headerDefinitions.columns;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataTable, setDatatable] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const tableData = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => headersData, [headersData]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useSortBy,
      usePagination
    );

  const toggleRowSelection = (Id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(Id)) {
        // If already selected, remove it
        return prev.filter((id) => id !== Id);
      } else {
        // Otherwise, add it
        return [...prev, Id];
      }
    });
  };

  const handleSort = async (columnId) => {
    // Will Sort By *accessor* in HeaderJSON File
    setSortConfig((prevSortConfig) => {
      let direction = "ASC";
      if (
        prevSortConfig.key === columnId &&
        prevSortConfig.direction === "ASC"
      ) {
        direction = "DESC";
      }
      return { key: columnId, direction };
    });
  };

  const handleNextPage = () => {
    setPageIndex((current) => {
      const nextPageIndex = current + 1;
      setCanNextPage(nextPageIndex < pageCount - 1);
      setCanPreviousPage(true);
      return nextPageIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((current) => {
      const previousPageIndex = Math.max(0, current - 1);
      setCanPreviousPage(previousPageIndex > 0);
      setCanNextPage(true);
      return previousPageIndex;
    });
  };

  const fetchData = async () => {
    console.log("Fetching data...");
    try {
      const data= await getLearnerProgress({
        learner_id: learner_id,
        classroom_id: classroom_id
      });
      if (data) {
        console.log("Data received:", data);
        setDatatable(data.sections ?? []);
        setProgressData(data)
      }
    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData()
  },[])


  return (
    <div className="container-fluid">
      <div className="box-white">
        <Row className="mb-3">
            {/* <h2>{progressData.cla_name}</h2> */}
            <h2> ห้องเรียน |  <Link className="text-decoration-none" to={`/classlearner/${progressData?.cla_id}`}>{progressData?.cla_name}</Link> </h2>
            <h2>{progressData?.lea_code} <span className="text-header">{progressData?.lea_name}</span></h2>
        </Row>

        {/* TABLE */}
        <div className="d-flex justify-content-between align-items-center mx-2 py-3">
          <div className="d-none d-md-block">
            <span className="pr-2 align-self-center text-sm fw-bold">
              Show :{" "}
            </span>
            <span>
              <select
                style={{ border: "none", cursor: "pointer" }}
                className="select-page mr-2"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(Number(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
              </select>
            </span>
            <span>Entries</span>
          </div>
          <div className="d-flex search-container">
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="ค้นหา"
              className="search-input"
            />
            <button className="search-button" onClick={fetchData}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          {" "}
          <Table
            striped
            bordered
            {...getTableProps()}
            className="table text-center "
            style={{ borderCollapse: "collapse" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      {/* Add a clickable sort icon */}
                      <TableHeaderButton
                        column={column}
                        sortConfig={sortConfig}
                        handleSort={handleSort}
                      />
                    </th>
                  ))}
                  <th>เครื่องมือ</th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {" "}
              <tr className="space-table"></tr>
              {rows.length > 0 ? (
                rows.map((row, rowIndex) => {
                  prepareRow(row);
                  const actualIndex = pageIndex * rowsPerPage + rowIndex + 1;
                  const isSelected = selectedRowIds.includes(row.original.id);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => toggleRowSelection(row.original.id)}
                      className={isSelected ? "selected-row" : ""}
                      style={isSelected ? { backgroundColor: "#f0f0f0" } : {}}
                    >
                      <td>{row.original?.cls_name ?? ""}</td>
                      <td>{row.original?.cls_score ?? ""}</td>
                      <td>
                        <span
                          style={{color: helpers.getStatusColor(row.original?.cls_learn_status)}}
                        >
                          {helpers.getLearnStatus(row.original?.cls_learn_status) ?? ""}
                        </span>
                      </td>
                      <td>
                        <button
                          className="mx-1 icon-btn"
                          style={{ background: "transparent" }}
                          onClick={() => handleViewClick(row.original)}
                        >
                          <img
                            src={viewIcon}
                            alt="View"
                            className="ic-actions"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center">
                  <td colSpan={100} style={{ textAlignLast: "center" }}>
                    ไม่พบข้อมูล
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {/* END TABLE */}

        {/* Side Panel */}
        {selectedSection && (
            <div
              className={`side-panel ${showSidePanel ? "open" : ""}`}
              style={{ width: "70%" }}
            >
              <div className="learner-side-panel-header d-flex justify-content-between align-items-center">
                <div>
                  <h4>{selectedSection?.cls_name}</h4>
                  <h5 className="mt-1">
                    {progressData?.lea_code}
                    <span className="text-header">{progressData?.lea_name}</span>
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                  <p className="total-progress mb-0">
                    <span className="fs-0 fw-light mx-1">รวมทั้งหมด</span>
                    <span className="text-header fs-1 fw-bold mx-1">
                        {selectedSection?.total_points > 0 
                            ? ((selectedSection?.learner_point / selectedSection?.total_points) * 100).toFixed(2) + '%' 
                            : '0.00%'}
                    </span>
                  </p>
                  <Button
                    variant="link"
                    onClick={handleClose}
                    className="learner-close-btn ml-3"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </div>
              </div>
              <SidePanelContent progressData={progress} />
            </div>
          )}

      </div>
    </div>
  );
};

export default LearnerProgress;
