import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getClassroomByID = async (id) => {
    try {
        const response = await instance.get(`/classroom/get/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const getList = async (query)=> {
    try {
        const response = await instance.get(`/learner`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`/learner/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (data) => {
    try {
        const toCreate = {
            ins_id: data?.institution,
            lic_count: data?.count,
            lic_ready: data?.ready,
            lic_expired: data?.expired,
        }
        const response = await instance.post(`/learner`,toCreate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const edit = async (data) => {
    try {
        const toUpdate = {
            lic_id: data?.id,
            ins_id: data?.institution,
            lic_count: data?.count,
            lic_ready: data?.ready,
            lic_expired: data?.expired,
        }
        const response = await instance.put(`/learner/${data.id}`,toUpdate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}
  
